import {Button, Col, Container, Row} from "react-bootstrap";
import React, {CSSProperties, useEffect, useLayoutEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../app/hooks";
import {Link, useMatch} from "react-router-dom";
import PfeilPrev from "./../assets/05_Detailseite/pf-vor-zurueck.svg"
import BlockVa from "../components-low/BlockVa";
import FooterNavigation from "../components-high/FooterNavigation";
import {setTitle} from "../features/meta/metaSlice";
import {setHaserollup} from "../features/haserollup/haserollupSlice";
import LogoEuropawoche from "../assets/03_Karte/logo-ew-2022.svg";

const PageBundesland = () => {
    const bundeslandData = useAppSelector((state) => state.bundesland.value)
    const vaData = useAppSelector((state) => state.veranstaltung.value)
    const [color, setColor] = useState('')
    const [bgcolor, setBgcolor] = useState('')
    const [wappen, setWappen] = useState('')
    const [landname, setLandname] = useState('')
    const [hauptseite, setHauptseite] = useState('')
    const [programm, setProgramm] = useState('')
    const [name, setName] = useState('')
    const [mail, setMail] = useState('')
    const [tel, setTel] = useState('')
    const [ahover, setAhover] = useState(false)

    const [prevlink, setPrevlink] = useState('')
    const [nextlink, setNextlink] = useState('')


    let url = useMatch("bundesland/:slug");
    let slug = '';
    if (url?.params.slug != null) {
        slug = url.params.slug;
    }

    const dispatch = useAppDispatch();
    dispatch(setTitle('Europawoche 2022 - ' + slug.toString().toUpperCase()))

    useLayoutEffect(() => {
        // window.scrollTo(0, 0)
    });

    useEffect(() => {
        // window.scrollTo(0, 0)
    },[])

    useEffect(() => {
        let found = false
        let prev = ''
        let actual = ''
        let next= ''
        bundeslandData.map(function (item) {
            if (found) {
                setNextlink(item.slug)
                next = item.slug
                found = false
                window.scrollTo(0, 0)
            }

            if (decodeURI(item.slug) === slug) {
                found = true
                actual = item.slug
                setPrevlink(prev)
                setColor(item.acf.farbe)
                setBgcolor(item.acf.farbe_bg)
                setWappen(item.acf.wappen)
                setLandname(item.title.rendered)
                setHauptseite(item.acf.link_hauptseite)
                setProgramm(item.acf.link_programmubersicht)
                setName(item.acf.kontakt_name)
                setMail(item.acf.kontakt_mail)
                setTel(item.acf.kontakt_telefon)
            }
            prev = item.slug
        })

        if(next===''){
            setNextlink('baden-wu%cc%88rttemberg')
        }
    }, [bundeslandData, slug])

    useEffect(() => {
        if(prevlink==='') setPrevlink('thu%cc%88ringen')
    },[prevlink])



    const VorlageStyle: CSSProperties = {
        position: "absolute",
        zIndex: "5000",
        backgroundImage: 'url("./../assets/screens/detailseite.png")',
        backgroundRepeat: "no-repeat",
        backgroundPosition: "top center",
        width: "100%",
        height: "2406px",
        opacity: "0.3",
        top: "0",
    }

    let success = 0

    dispatch(setHaserollup(false))

    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const thisday = Number(year + month + day)

    return (
        <>
            {/*Overlay*/}
            {/*<div style={VorlageStyle}></div>*/}


            <Container className="fullwidth bg-europablau color-black sticky-top p-0"
                       style={{zIndex: "2800"}}
            >
                <Container fluid>

                    <Row className=" " style={{maxWidth: "1322px"}}>
                        <Col xs={3} className="p-0 position-relative">
                            <Link to="/">
                                <img src={LogoEuropawoche} alt="" className="w-100 position-absolute "
                                     style={{boxShadow: "0px 3px 6px #00000029",maxWidth:"265px"}}
                                />
                            </Link>
                        </Col>
                        <Col xs={6} className=" text-center my-auto position-relative py-2 py-sm-3">
                            <Link to="/" className="text-decoration-none goleftimage position-absolute ps-3 ps-sm-5"
                                  style={{left: "0"}}
                            >
                                <img src={PfeilPrev} className="rotate180 me-lg-2 pfeilgoleft pfeilheaderbundesland" alt=""/>
                                <img src={PfeilPrev} className="rotate180 pfeilgoleft d-xl-none pfeilheaderbundesland" alt=""
                                    // style={{position: "relative", left: "-10px"}}
                                />
                                <span className="pt16-regilar-weiss d-none d-xl-inline">Zur Karte</span>
                            </Link>

                            {
                                prevlink ?
                                    <Link className="cursor-pointer" to={'/bundesland/' + prevlink}>
                                        <img src={PfeilPrev}
                                             className="rotate180 me-2-2rem goleft pfeilheaderbundesland"
                                             alt=""/></Link> : <></>
                            }

                            {
                                nextlink ?
                                    <Link className="cursor-pointer" to={'/bundesland/' + nextlink}>
                                        <img src={PfeilPrev} className="goright pfeilheaderbundesland"
                                             alt=""/></Link> : <></>
                        }
                    </Col>
                </Row>
                </Container>
            </Container>

            <Container className="d-none fullwidth mb-3 bg-europabla position-fixed check" style={{zIndex: "1700", height: "70px"}}>
                <Row>
                    <Col>
                        <Container fluid className="">
                            <Row style={{zIndex: "1300"}} className="bg-europabla">
                                <Col xs={2} lg={4} className="text-center h-100 mt- pt-3  offset-3 offset-lg-2 ps-4">
                                    <Link to="/" className="text-decoration-none goleftimage">

                                        <img src={PfeilPrev} className="rotate180 me-lg-2 pfeilgoleft" alt=""/>
                                        <img src={PfeilPrev} className="rotate180 pfeilgoleft d-lg-none" alt=""
                                        style={{position:"relative",left:"-10px"}}
                                        />
                                        <span className="pt16-regilar-weiss d-none d-lg-block">
                                            Zur Übersicht
                                        </span>
                                    </Link>
                                </Col>
                                <Col xs={2} className="text-center mt- pt-3  bg-europablau">
                                    {
                                        prevlink ?
                                            <Link className="cursor-pointer" to={'/bundesland/' + prevlink}>
                                                <img src={PfeilPrev}
                                                     className="rotate180 me-2-2rem goleft"
                                                     alt=""/></Link> : <></>
                                    }

                                    {
                                        nextlink ?
                                            <Link className="cursor-pointer" to={'/bundesland/' + nextlink}>
                                                <img src={PfeilPrev} className="goright"
                                                     alt=""/></Link> : <></>
                                    }
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>

            <Container className="d-non fullwidth position-relative" style={{backgroundColor: bgcolor, color: color}}>
                <Row style={{height: "142px"}}>
                    <Col className="text-center my-auto">
                        <img src={wappen} alt="" style={{height: "95px", position: "relative", top: "10px"}}/>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-center pb-3">
                        <h1 className="h1-30pt-bold-weiss" style={{color: color}}>
                            Veranstaltungen zur Europawoche
                        </h1>
                        <h2 className="h3-18pt-bold-weiss-upper" style={{color: color}}>{landname}</h2>
                    </Col>
                </Row>
            </Container>

            <Container className="d-non fullwidth bg-white  pt-2-6-rem">
                <Row>
                    <Col>
                        <Container fluid className="p-0 pb-5">
                            {
                                vaData.map(function (item, index) {

                                    let thisdatum = Number(item.acf?.datum_von)


                                    if ((decodeURI(item?.acf?.bundesland.post_name) === slug) && (thisdatum >= thisday)) {
                                        success = success + 1
                                        return (
                                            <Row key={index} className="">
                                                {
                                                    success > 1 ? <>
                                                        <Col xs={12}>
                                                            <hr className="my-4" style={{color: bgcolor, height: "7px"}}/>
                                                        </Col>
                                                    </> : <></>
                                                }
                                                <BlockVa item={item} archiv={false} reverse={((success % 2) === 1) ? false : true}/>
                                            </Row>
                                        )
                                    }
                                })
                            }
                            {
                                vaData.map(function (item, index) {
                                    let thisdatum = Number(item.acf?.datum_von)
                                    if ((decodeURI(item?.acf?.bundesland.post_name) === slug) && (thisdatum < thisday)) {
                                        success = success + 1
                                        return (
                                            <Row key={index} className="">
                                                {
                                                    success > 1 ? <>
                                                        <Col xs={12}>
                                                            <hr className="my-4" style={{color: bgcolor, height: "7px"}}/>
                                                        </Col>
                                                    </> : <></>
                                                }
                                                <BlockVa item={item} archiv={true} reverse={((success % 2) === 1) ? false : true}/>
                                            </Row>
                                        )
                                    }
                                })
                            }
                            <Row>
                                <Col className="text-center mt-5">
                                    <a href={programm} target="_blank">
                                        {
                                            programm ?
                                                <Button className="mehrzurew hover-white my-auto p- h-auto p-3"
                                                        style={{backgroundColor: bgcolor}}
                                                >
                                            <div className="ort-16pt-semibold-schwarz-upper hover-target ps-4 pe-4 pb-"
                                                  style={{color: color}}
                                            >MEHR ZUR EUROPAWOCHE IN {landname}</div>
                                                </Button> :
                                                <></>
                                        }
                                    </a>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>

            <Container className="fullwidth  pb-5 pb-xxl-0" style={{backgroundColor: bgcolor, color: color}}>
                <Row>
                    <Col className="text-center py-4 kontaktdaten-16pt-semibild-weiss d-non" style={{color: color}}>
                        <strong>{name}</strong><br/>
                        <hr style={{minWidth: "300px", maxWidth: "516px", color: color}} className="mx-auto"/>
                        {
                            tel ?
                                <>
                                    Telefon: <a className="kontaktdaten-16pt-semibild-weiss text-decoration-none  no-hover" style={{color: color}}
                                                href={'tel:' + tel}>{tel}</a><br/>
                                </>
                                : <></>
                        }

                        {
                            mail ?
                                <>
                                    E-Mail: <a className="kontaktdaten-16pt-semibild-weiss text-decoration-none  no-hover" style={{color: color}}
                                               href={'mailto:' + mail}>{mail}</a><br/>
                                </>
                                :
                                <></>
                        }

                        {
                            ahover ?
                                <svg style={{position: "relative", top: "-2px", left: "-11px"}} xmlns="http://www.w3.org/2000/svg" width="10.8" height="18"
                                     viewBox="0 0 10.8 18">
                                    <path style={{fill: color}} className="a" d="M0,21.5l7.626,7.574L.008,36.483,1.64,38l9.16-8.91L1.648,20Z"
                                          transform="translate(0 -20)"/>
                                </svg> :
                                <svg style={{position: "relative", top: "-2px", left: "-16px"}} xmlns="http://www.w3.org/2000/svg" width="10.8" height="18"
                                     viewBox="0 0 10.8 18">
                                    <path style={{fill: color}} className="a" d="M0,21.5l7.626,7.574L.008,36.483,1.64,38l9.16-8.91L1.648,20Z"
                                          transform="translate(0 -20)"/>
                                </svg>

                        }
                        {
                            hauptseite ?
                                <a className="kontaktdaten-16pt-semibild-weiss text-decoration-none  no-hover h-auto" style={{color: color}}
                                   href={hauptseite}
                                   target="_blank"
                                   onMouseEnter={() => setAhover(true)}
                                   onMouseLeave={() => setAhover(false)}
                                >{hauptseite}</a> :
                                <></>
                        }
                    </Col>
                </Row>
            </Container>

            <FooterNavigation absolute={true}/>
        </>
    )
}

export default PageBundesland;
