import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from "../../app/store";
import {MenuDataT} from "../../library/MenuT";


interface state {
    value: {
        title:string,
        description:string,
        canonical:string
    }
}

const initialState: state = {
    value: {
        title: "1",
        description: "2",
        canonical: "3"
    }
}

export const metaSlice = createSlice({
    name: 'meta',
    initialState,
    reducers: {
        setTitle: (state, action: PayloadAction<string>) => {
            state.value.title = action.payload
        },
        setDescription: (state, action: PayloadAction<string>) => {
            state.value.description = action.payload
        },
        setCanonical: (state, action: PayloadAction<string>) => {
            state.value.canonical = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const {setTitle} = metaSlice.actions
export const {setDescription} = metaSlice.actions
export const {setCanonical} = metaSlice.actions

export const selectMeta = (state: RootState) => state.meta.value

export default metaSlice.reducer
