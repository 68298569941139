function GetMonat(monat: any) {


    if (monat === '04') {
        return 'April'
    }

    if (monat === '05') {
        return 'Mai'
    }

    if (monat === '06') {
        return 'Juni'
    }

    return ''
}

export default GetMonat;
