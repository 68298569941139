import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import mediaReducer from '../features/media/mediaSlice';
import mainmenuReducer from '../features/mainmenu/mainmenuSlice';
import footermenuReducer from '../features/footermenu/footermenuSlice';
import startseiteReducer from '../features/startseite/startseiteSlice';
import datenschutzReducer from '../features/datenschutz/datenschutzSlice';
import impressumReducer from '../features/impressum/impressumSlice';
import bundeslandReducer from '../features/bundesland/bundeslandSlice';
import veranstaltungReducer from '../features/veranstaltung/veranstaltungSlice';
import zoomReducer from '../features/zoom/zoomSlice';
import metaReducer from '../features/meta/metaSlice';
import zeilenansichtReducer from '../features/zeilenansicht/zeilenansichtSlice';
import modalReducer from '../features/modal/modalSlice';
import modaljugendjahrReducer from '../features/modaljugendjahr/modaljugendjahr';
import showrollupReducer from '../features/showrollup/showrollupSlice';
import haserollupReducer from '../features/haserollup/haserollupSlice';

export const store = configureStore({
  reducer: {
    media: mediaReducer,
    mainmenu: mainmenuReducer,
    footermenu: footermenuReducer,
    startseite: startseiteReducer,
    datenschutz: datenschutzReducer,
    impressum: impressumReducer,
    bundesland: bundeslandReducer,
    veranstaltung: veranstaltungReducer,
    zoom: zoomReducer,
    meta: metaReducer,
    zeilenansicht: zeilenansichtReducer,
    modal: modalReducer,
    modaljugendjahr: modaljugendjahrReducer,
    showrollup: showrollupReducer,
    haserollup: haserollupReducer,
  },
});


export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
