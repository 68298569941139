import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from "../../app/store";
import {MediaT} from "../../library/MediaT";


interface mediaState {
    value: any
}

const initialState: mediaState = {
    value: [{}]
}

export const impressumSlice = createSlice({
    name: 'impressum',
    initialState,
    reducers: {
        setImpressum: (state, action: PayloadAction<any>) => {
            state.value = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const {setImpressum} = impressumSlice.actions
export const selectImpressum = (state: RootState) => state.impressum.value

export default impressumSlice.reducer
