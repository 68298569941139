import {Col, Container, Row} from "react-bootstrap";
import {Link, useLocation} from "react-router-dom";
import {CSSProperties, useEffect, useState} from "react";


const KarteStyle: CSSProperties = {
    textDecoration: "none !important"
}

const Header = () => {
    const location = useLocation();
    const [karteactive, setKarteactive] = useState('')
    const [tabelleactive, setTabelleactive] = useState('')

    useEffect(() => {
        if (location.pathname === '/tabelle') {
            setTabelleactive('bg-europagelb kartenbutton')
            setKarteactive('ms-0 kartenbutton')
        }
        if (location.pathname === '/') {
            setKarteactive('ms-0 bg-europagelb kartenbutton')
            setTabelleactive('kartenbutton')
        }
    }, [location])

    return (
        <Container className="fullwidth color-white sticky-top position-absolute d-none d-xxl-block">
            <Row>
                <Col>
                    <Container fluid className="p-0 bg-dange">
                        <Row>
                            <Col xs={0} sm={4}>
                                <Container><Row><Col className="">
                                </Col></Row></Container>
                            </Col>
                            <Col xs={12} sm={4} className="offset-2 offset-sm-0">
                                <Container className="bg-ligh">
                                    <Row>
                                        <Col  className="ps-2">
                                            <Link to="/" className="text-decoration-none">
                                                <Container fluid id="kartelink" className={karteactive}>
                                                    <Row className="h-100">
                                                        <Col className="my-auto karte-tabelle-17pt-schwrz-upper kartelinktext">
                                                            Karte<br/>
                                                        </Col>
                                                    </Row>
                                                </Container>
                                            </Link>
                                        </Col>
                                        <Col className="offset-sm-3 pe-0">
                                        {/*<Col className="offset-sm-3 pe-0 col-sm-2">*/}
                                            <Link to="/tabelle" className="text-decoration-none">
                                                <Container fluid id="tabellelink"  className={tabelleactive}>
                                                    <Row className="h-100">
                                                        <Col className="my-auto karte-tabelle-17pt-schwrz-upper kartelinktext">
                                                            Tabelle
                                                        </Col>
                                                    </Row>
                                                </Container>
                                            </Link>
                                        </Col>
                                    </Row>
                                </Container>
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
        </Container>
    )
}

export default Header;
