import React, {useEffect} from 'react';
import axios from "axios";
import {URL_BUNDESLAND, URL_DATENSCHUTZ, URL_FOOTERMENU, URL_IMPRESSUM, URL_MAINMENU, URL_MEDIA, URL_START, URL_VA} from "./library/constants";
import {setMainmenu} from "./features/mainmenu/mainmenuSlice";
import {useAppDispatch, useAppSelector} from "./app/hooks";
import {setFootermenu} from "./features/footermenu/footermenuSlice";
import {setMedia} from "./features/media/mediaSlice";
import {setStartseite} from "./features/startseite/startseiteSlice";
import {setDatenschutz} from "./features/datenschutz/datenschutzSlice";
import {setImpressum} from "./features/impressum/impressumSlice";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import PageImpressum from "./pages/PageImpressum";
import PageDatenschutz from "./pages/PageDatenschutz";
import {setBundesland} from "./features/bundesland/bundeslandSlice";
import {setVeranstaltung} from "./features/veranstaltung/veranstaltungSlice";
import {useMatomo} from '@datapunt/matomo-tracker-react'
import {setCanonical, setDescription, setTitle} from "./features/meta/metaSlice";
import Meta from "./components-high/Meta";
import Matomo from "./components-high/Matomo";
import PageListe from "./pages/PageListe";
import PageEuropakarte from "./pages/PageEuropakarte";
import PageBundesland from "./pages/PageBundesland";
import ModalJugendjahr from "./components-high/ModalJugendjahr";
import Rollup from "./components-high/Rollup";

function App() {
    // MATOMO
    const {trackPageView} = useMatomo()
    const haseRollup = useAppSelector((state) => state.haserollup.value)

    useEffect(() => {
        trackPageView({
            documentTitle: "Testlink", // optional
            href: window.location.href, // optional
        })
    }, [trackPageView])

    // REDUX
    const dispatch = useAppDispatch();
    useEffect(() => {
        //Mainmenu
        axios
            .get(URL_MAINMENU)
            .then((result) => {
                dispatch(setMainmenu(result.data));
            })
            .catch((error) => {
                console.log(error);
            });

        //Footermenu
        axios
            .get(URL_FOOTERMENU)
            .then((result) => {
                dispatch(setFootermenu(result.data));
            })
            .catch((error) => {
                console.log(error);
            });

        //Media
        axios
            .get(URL_MEDIA)
            .then((result) => {
                dispatch(setMedia(result.data));
            })
            .catch((error) => {
                console.log(error);
            });

        //Startseite
        axios
            .get(URL_START)
            .then((result) => {
                dispatch(setStartseite(result.data));
            })
            .catch((error) => {
                console.log(error);
            });

        //Datenschutz
        axios
            .get(URL_DATENSCHUTZ)
            .then((result) => {
                dispatch(setDatenschutz(result.data));
            })
            .catch((error) => {
                console.log(error);
            });

        //Impressum
        axios
            .get(URL_IMPRESSUM)
            .then((result) => {
                dispatch(setImpressum(result.data));
            })
            .catch((error) => {
                console.log(error);
            });

        //Bundesland
        axios
            .get(URL_BUNDESLAND)
            .then((result) => {
                dispatch(setBundesland(result.data));
            })
            .catch((error) => {
                console.log(error);
            });

        //Veranstaltungen
        axios
            .get(URL_VA)
            .then((result) => {
                dispatch(setVeranstaltung(result.data));
            })
            .catch((error) => {
                console.log(error);
            });

    }, [dispatch])

    dispatch(setTitle('Europawoche 2022'))
    dispatch(setDescription('Europawoche 2022'))
    dispatch(setCanonical('https://europawoche2022.de'))

    return (
        <Router>
            <Meta/>
            <Matomo/>

            <Routes>
                <Route path="/impressum" element={<PageImpressum/>}/>
                <Route path="/datenschutz" element={<PageDatenschutz/>}/>
                <Route path="/bundesland/:slug" element={<PageBundesland/>}/>
                <Route path="/tabelle" element={<PageListe/>}/>
                <Route path="/" element={<PageEuropakarte/>}/>
            </Routes>
            <ModalJugendjahr/>

            {
                haseRollup ? <Rollup/> : <></>
            }
        </Router>
    );
}

export default App;
