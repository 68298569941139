import React, {CSSProperties, useState} from "react";
import {BundeslandDataT} from "../library/BundeslandT";
import {Col, Container, Row} from "react-bootstrap";
import {useAppDispatch, useAppSelector} from "../app/hooks";
import {setZeile, setZeileninhalt} from "../features/zeilenansicht/zeilenansichtSlice";
import Pfeildown from '../assets/04_Tabelle/pfeil-bl.svg'

interface Props {
    bundeslandData: BundeslandDataT
    row: number
    spalten: number
}

const ContainerStyle: CSSProperties = {
    height: "110px !important",
    overflow: "hidden",
    maxHeight: "110px",
    minHeight: "110px",
    transition:"0.3s ease-out"
}

const BundeslandRow = ({bundeslandData, row, spalten}: Props) => {
    const ZeilenansichtData = useAppSelector((state) => state.zeilenansicht.value)
    const dispatch = useAppDispatch();
    const[showpfeil,setShowpfeil]=useState(false)


    const handleSlug = (slug: string) => {
        console.log(slug+  ' | ' + ZeilenansichtData.inhalt)
        if(slug === ZeilenansichtData.inhalt){
            dispatch(setZeile(0))
            dispatch(setZeileninhalt(''))
        }else{
            dispatch(setZeile(row))
            dispatch(setZeileninhalt(slug))
        }

    }



    return (
        <Row xs={2} xl={4} className="h-100 my-auto">
            {
                bundeslandData.map(function (item, index) {
                    // if (index > (((row - 1) * 4) - 1) && index < (row * 4)) {
                    if (index > (((row - 1) * spalten) - 1) && index < (row * spalten)) {
                        return (
                            <Col key={index} className="mb-4 h-100 bg-dar my-auto"
                                 onClick={(e) => handleSlug(item.slug)}
                            >
                                <Container fluid
                                           className={
                                               (ZeilenansichtData.inhalt === item.slug)
                                                   ?
                                                   'bg-europagelb  text-left p- pe- cursor-pointer laenderbutton my-auto'
                                                   :
                                                   'bg-white  text-left p- pe- cursor-pointer laenderbutton my-auto'
                                           }
                                           style={ContainerStyle}>

                                    <Row className="h-110" >
                                        {/*<Col sm={4} className="bg-danger my-sm-auto ps-20 text-center justify-content-end align-text-bottom text-sm-start" style={{height: "110px !important"}}>*/}
                                        <Col sm={4} className="d-flex d-sm-block align-items-end align-items-sm-center my-sm-auto  ps-20 text-center justify-content-center align-text-bottom text-sm-start" style={{height: "110px !important"}}>
                                            <img src={item.acf.wappen} alt={item.title.rendered} className="tabelle-wappen" />
                                        </Col>
                                        <Col sm={8} className="align-items-end bundesland-20pt-semibold-weiss-upper my-auto ps-2 tabelle-land text-center text-sm-start"
                                             style={{height: "110px !important"}}>
                                            {item.title.rendered}
                                        </Col>
                                    </Row>
                                </Container>
                                <Container fluid className="position-relative" style={{top: "18px", zIndex: "100", height: "0"}}>
                                    <Row>
                                        <Col className="text-center d-non d-xl-block">
                                            {
                                                (ZeilenansichtData.inhalt === item.slug) ? <img src={Pfeildown} alt=""/> : <></>
                                            }
                                        </Col>
                                    </Row>
                                </Container>
                            </Col>
                        )
                    }
                    return true
                })
            }
        </Row>
    )
}

export default BundeslandRow;
