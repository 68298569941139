import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from "../../app/store";
import {MediaT} from "../../library/MediaT";


interface mediaState {
    value: MediaT
}

const initialState: mediaState = {
    value: [{}]
}

export const mediaSlice = createSlice({
    name: 'media',
    initialState,
    reducers: {
        setMedia: (state, action: PayloadAction<MediaT>) => {
            state.value = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const {setMedia} = mediaSlice.actions
export const selectMedia = (state: RootState) => state.media.value

export default mediaSlice.reducer
