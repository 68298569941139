import React, {CSSProperties, useEffect, useState} from "react";
import Europa from "./../assets/03_Karte/europa-karte-5400px-png8.png"
import {useAppDispatch, useAppSelector} from "../app/hooks";
import {setCanonical, setDescription, setTitle} from "../features/meta/metaSlice";
import DeutschlandkarteEuropa from "../components-low/DeutschlandkarteEuropa";
import {Col, Container, Modal, Row} from "react-bootstrap";
import FooterNavigation from "../components-high/FooterNavigation";
import Header from "../components-high/Header";
import LogoEuropawoche from "../assets/01_Header/ew-logo-2022.svg";
import LogoJugendjahr from "../assets/03_Karte/logo-eyy-tb.svg";
import LogoJugendjahrHover from "../assets/04_Tabelle/logo-eyy-tb-hover.svg";
import {setmodaljugendjahr} from "../features/modaljugendjahr/modaljugendjahr";
import Logos from "../components-high/Logos";
import ZeilenansichtKarte from "../components-high/ZeilenansichtKarte";
import {setHaserollup} from "../features/haserollup/haserollupSlice";
import {setZoomFactor} from "../features/zoom/zoomSlice";
import {setZeileninhalt} from "../features/zeilenansicht/zeilenansichtSlice";
import {setModalStatus} from "../features/modal/modalSlice";

const EuropaStyle: CSSProperties = {
    objectFit: "cover",
    width: "100%",
    height: "100%",
    objectPosition: "50% 50%",
    position: "relative",
}

const FrameStyle: CSSProperties = {
    width: "100%",
    height: "100%",
    position: "absolute",
    overflow: "hidden"
}

const ModalStyle: CSSProperties = {
    width: "100%",
    maxWidth: "unset",
    position: "absolute",
    zIndex: "10",
}

const ContainerStyle: CSSProperties = {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    zIndex: "1000"
}


const VorlageStyle: CSSProperties = {
    position: "absolute",
    zIndex: "500000",
    backgroundImage: 'url("./../assets/screens/karte.png")',
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top center",
    width: "100%",
    height: "2406px",
    opacity: "0.2",
    // top: "-220px",
    bottom:"-1469px"
}


const PageEuropakarte = () => {
    const zoomData = useAppSelector((state) => state.zoom.value)
    const modalData = useAppSelector((state) => state.modal.value)
    const [scaleStyle, setScaleStyle] = useState("scale(1)")
    const dispatch = useAppDispatch()

    const [jjhover, setJjhover] = useState(LogoJugendjahr)

    useEffect(() => {
        setScaleStyle("scale(" + zoomData + ")")
    }, [zoomData])

    // console.log(window.innerHeight)

    useEffect(() => {

        if (window.innerHeight < 1200) {
            dispatch(setZoomFactor(4.5))
            console.log("1200")
        }

        if (window.innerHeight < 1100) {
            dispatch(setZoomFactor(3))
            console.log("1100")
        }

        if (window.innerHeight < 1000) {
            dispatch(setZoomFactor(3))
            console.log("1000")
        }

        if (window.innerHeight < 900) {
            dispatch(setZoomFactor(2.8))
            console.log("900")
        }

        if (window.innerHeight < 800) {
            dispatch(setZoomFactor(3))
            console.log("800")
        }

        if (window.innerHeight < 700) {
            dispatch(setZoomFactor(3))
            console.log("700")
        }

        if (window.innerHeight < 600) {
            dispatch(setZoomFactor(3))
            console.log("600")
        }


    }, [window.innerHeight])


    useEffect(() => {
        dispatch(setModalStatus(false))
        dispatch(setZeileninhalt(''))
    }, [])


    dispatch(setTitle('Europawoche 2022 - Kartenansicht'))
    dispatch(setDescription('Europawoche 2022'))
    dispatch(setCanonical('https://europawoche2022.de'))

    const handleJugendjahr = () => {
        dispatch(setmodaljugendjahr(true))
    }
    dispatch(setHaserollup(true))

    return (
        <>
            {/*Overlay*/}
            {/*<div style={VorlageStyle}></div>*/}

            <Header/>
            {
                modalData.status ?
                    (
                        <Modal.Dialog style={ModalStyle}>
                            <ZeilenansichtKarte activeRow={0} karte={true}/>
                        </Modal.Dialog>
                    )
                    : (<></>)
            }

            <Logos transparent={false} ewonly={false}  ewfixed={false}  jugend={true}  navi={true}/>

            <Container className="fullwidth d-none" style={ContainerStyle}>
                <Row>
                    <Col>
                        <Container fluid>
                            <Row>
                                <Col xs={3} className="ps-">
                                    <Container style={{height: "140px"}} className="bg-white ">
                                        <img src={LogoEuropawoche} alt="" className="mt-3"/>
                                    </Container>
                                </Col>
                                <Col xs={3} className="text-end offset-6">
                                    <Container style={{height: "140px"}} className="bg-white cursor-pointer">
                                        <img alt=""
                                             className="mt-2"
                                             src={jjhover}
                                             onMouseEnter={() => setJjhover(LogoJugendjahrHover)}
                                             onMouseLeave={() => setJjhover(LogoJugendjahr)}
                                             onClick={(e) => handleJugendjahr()}
                                        />
                                    </Container>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>

            <div style={FrameStyle}>
                <img src={Europa} className="" style={{...{transform: scaleStyle}, ...EuropaStyle}}/>
            </div>
            <div style={FrameStyle}>
                <DeutschlandkarteEuropa/>
            </div>
            <FooterNavigation absolute={true}/>
        </>
    )
}

export default PageEuropakarte;
