import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from "../../app/store";

interface state {
    value: {
        status:boolean,
    }
}

const initialState: state = {
    value:{
        status:false,
    }
}

export const modaljugendjahrSlice = createSlice({
    name: 'modaljugendjahr',
    initialState,
    reducers: {
        setmodaljugendjahr: (state, action: PayloadAction<boolean>) => {
            state.value.status = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const {setmodaljugendjahr} = modaljugendjahrSlice.actions


export const selectmodaljugendjahr = (state: RootState) => state.modaljugendjahr.value
export default modaljugendjahrSlice.reducer
