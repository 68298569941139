import {Col, Container, Modal, Row} from "react-bootstrap";
import React, {useCallback, useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../app/hooks";
import {setTitle} from "../features/meta/metaSlice";
import {setmodaljugendjahr} from "../features/modaljugendjahr/modaljugendjahr";
import Backgroundimage from "../assets/02_EYY/eyy-hg-bild.jpg"
import Fgimage from "../assets/02_EYY/eyy-bild.png"
import Clickimage from "../assets/02_EYY/eyy-grafik.svg"
import ClickimageHover from "../assets/02_EYY/eyy-grafik-hover.svg"
import Modalclose from "../assets/02_EYY/zu.svg"
import ModalcloseHover from "../assets/02_EYY/zu-hover.svg"
import {useNavigate} from "react-router-dom";
import {setShowrollup} from "../features/showrollup/showrollupSlice";


const ModalJugendjahr = () => {
    const modal = useAppSelector((state) => state.modaljugendjahr.value)
    const dispatch = useAppDispatch();

    useEffect(() => {
        if(modal.status){
            dispatch(setShowrollup(false))
        }
    },[modal])

    const [mainimagehover, setMainimagehover] = useState(Clickimage)
    const [closeimagehover, setCloseimagehover] = useState(Modalclose)
    const [bgscale, setBgscale] = useState(1)

    const handleHover = (on: boolean) => {
        if (on) {
            setMainimagehover(ClickimageHover)
            setBgscale(1.05)
        } else {
            setMainimagehover(Clickimage)
            setBgscale(1)
        }
    }

    const navigate = useNavigate();
    const goSachsen = useCallback(() => navigate('/bundesland/sachsen'), [navigate]);


    const handleClose = () => dispatch(setmodaljugendjahr(false));

    return (
        <>

            <Modal
                show={modal.status}
                onHide={handleClose}
                backdrop={true}
                backdropClassName="jugendjahrbackdrop"
                keyboard={false}
                dialogClassName="jugendjahr"
                style={{width: "100%", height: "100%"}}
                onClick={() => dispatch(setmodaljugendjahr(false))}
                className="jugendjahrframe"
            >
                {/*<Container fluid className="p-0 overflow-hidden modaljugendjahr" style={{width: "920px", height: "517px"}}>*/}
                <Container fluid className="p-0 overflow-hidden modaljugendjahr" >
                    <Row className="">
                        <Col className="">
                            <img src={Backgroundimage} alt="" className=""
                                 style={{transform: "scale(" + bgscale + ")"}}
                            />
                        </Col>
                    </Row>
                    <Row className="position-absolute top-0">
                        <Col className="">
                            <img src={Fgimage} alt="" className=""/>
                        </Col>
                    </Row>
                    <Row className="position-absolute top-0">
                        <Col className="">
                            <a href="https://ejj2022.de/" target="_blank">
                                <img src={mainimagehover} alt="" className=""
                                     onMouseEnter={() => handleHover(true)}
                                     onMouseLeave={() => handleHover(false)}
                                />
                            </a>
                        </Col>
                        <div
                            className="position-absolute text-end p-0"
                            style={{position: "absolute", right: "12px", width: "auto", zIndex: "10000"}}

                        >
                            <img src={closeimagehover} alt="" className="cursor-pointer"
                                 onMouseEnter={() => setCloseimagehover(ModalcloseHover)}
                                 onMouseLeave={() => setCloseimagehover(Modalclose)}
                                 onClick={() => dispatch(setmodaljugendjahr(false))}
                            />
                        </div>
                    </Row>
                </Container>
            </Modal>

        </>
    )
}

export default ModalJugendjahr;
