import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from "../../app/store";
import {MediaT} from "../../library/MediaT";
import {VaT} from "../../library/VaT";
// import {VeranstaltungDataT} from "../../library/VeranstaltungT";


interface state {
    value: VaT
}

const initialState: state = {
    value: [{}]
}

export const veranstaltungSlice = createSlice({
    name: 'veranstaltung',
    initialState,
    reducers: {
        setVeranstaltung: (state, action: PayloadAction<any>) => {
            state.value = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const {setVeranstaltung} = veranstaltungSlice.actions
export const selectVeranstaltung = (state: RootState) => state.veranstaltung.value

export default veranstaltungSlice.reducer
