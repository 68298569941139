import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from "../../app/store";
import {MediaT} from "../../library/MediaT";


interface mediaState {
    value: any
}

const initialState: mediaState = {
    value: [{}]
}

export const datenschutzSlice = createSlice({
    name: 'datenschutz',
    initialState,
    reducers: {
        setDatenschutz: (state, action: PayloadAction<any>) => {
            state.value = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const {setDatenschutz} = datenschutzSlice.actions
export const selectDatenschutz = (state: RootState) => state.datenschutz.value

export default datenschutzSlice.reducer
