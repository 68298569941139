import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from "../../app/store";
import {MediaT} from "../../library/MediaT";
import {BundeslandDataT} from "../../library/BundeslandT";


interface state {
    value: BundeslandDataT
}

const initialState: state = {
    value: [{
        title:"",
        link: "",
        slug: "",
        acf:false
    }]
}

export const bundeslandSlice = createSlice({
    name: 'bundesland',
    initialState,
    reducers: {
        setBundesland: (state, action: PayloadAction<any>) => {
            state.value = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const {setBundesland} = bundeslandSlice.actions
export const selectBundesland = (state: RootState) => state.bundesland.value

export default bundeslandSlice.reducer
