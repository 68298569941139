import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from "../../app/store";
import {MediaT} from "../../library/MediaT";
import {BundeslandDataT} from "../../library/BundeslandT";


interface state {
    value: number
}

const initialState: state = {
    value: 2.8
}

export const zoomSlice = createSlice({
    name: 'zoom',
    initialState,
    reducers: {
        setZoomUp: (state) => {
            state.value =state.value+0.1
        },
        setZoomDown: (state) => {
            state.value>1 ? state.value =state.value-0.1 : state.value =state.value
            // state.value =state.value-0.1
        },
        setZoomFactor: (state, action: PayloadAction<number>) => {
            state.value =action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const {setZoomUp} = zoomSlice.actions
export const {setZoomDown} = zoomSlice.actions
export const {setZoomFactor} = zoomSlice.actions
export const selectZoom = (state: RootState) => state.zoom.value

export default zoomSlice.reducer
