import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import type {RootState} from "../../app/store";
import {MenuDataT} from "../../library/MenuT";

interface mainmenuState {
    value:MenuDataT
}

const initialState: mainmenuState = {
    value: [{
        ID:0,
        title:'',
        url:'',
    }],
}

export const mainmenuSlice = createSlice({
    name: 'mainmenu',
    initialState,
    reducers: {
        setMainmenu: (state, action: PayloadAction<MenuDataT>) => {
            state.value = action.payload
        },
    },
})

export const {setMainmenu} = mainmenuSlice.actions
export const selectMainmenu = (state: RootState) => state.mainmenu.value
export default mainmenuSlice.reducer
