import {Col, Container, Row} from "react-bootstrap";
import LogoEuropawoche from "../assets/03_Karte/logo-ew-2022.svg";
import LogoEuropawocheTransparent from "../assets/03_Karte/logo-ew-2022-transparent.svg";
import LogoJugendjahrHover from "../assets/03_Karte/logo-eyy-hover.svg";
import LogoJugendjahrHoverTransparent from "../assets/03_Karte/logo-eyy-hover-transparent.svg";
import LogoJugendjahr from "../assets/03_Karte/logo-eyy-n.svg";
import LogoJugendjahrTransparent from "../assets/03_Karte/logo-eyy-n-transparent.svg";
import React, {CSSProperties, useEffect, useState} from "react";
import {setmodaljugendjahr} from "../features/modaljugendjahr/modaljugendjahr";
import {useAppDispatch} from "../app/hooks";
import {Link, useLocation} from "react-router-dom";
import Iconkarte from "../assets/01_Header/icon-karte.svg"
import IconkarteHover from "../assets/01_Header/icon-karte-hover.svg"
import Icontabelle from "../assets/01_Header/icon-tabelle.svg"
import IcontabelleHover from "../assets/01_Header/icon-tabelle-hover.svg"

const ContainerStyle: CSSProperties = {
    position: "absolute",
    // position: "fixed",
    top: "0",
    left: "0",
    // width: "100%",
    // zIndex: "1500"
}

interface props {
    transparent: boolean
    ewonly: boolean
    ewfixed: boolean
    jugend: boolean
    navi: boolean
}

const Logos = ({transparent, ewonly, ewfixed, jugend, navi}: props) => {
    const dispatch = useAppDispatch()
    const location = useLocation();
    const [karteactive, setKarteactive] = useState('')
    const [tabelleactive, setTabelleactive] = useState('')
    const [hoverkarteactive, sethoverKarteactive] = useState(false)
    const [hovertabelleactive, sethoverTabelleactive] = useState(false)

    useEffect(() => {
        if (location.pathname === '/tabelle') {
            setTabelleactive('bg-europagelb')
            setKarteactive('bg-white')
            sethoverTabelleactive(true)
            sethoverKarteactive(false)
        }
        if (location.pathname === '/') {
            setKarteactive('bg-europagelb')
            setTabelleactive('bg-white')
            sethoverTabelleactive(false)
            sethoverKarteactive(true)
        }
    }, [location])


    const [jjhover, setJjhover] = useState(
        transparent ? LogoJugendjahrTransparent : LogoJugendjahr
    )

    const handleJugendjahr = () => {
        dispatch(setmodaljugendjahr(true))
    }

    return (
        <>
            {/*MOBIL normal*/}
            {/*<Container fluid  style={{height: "100px", zIndex: "1200"}}*/}
            <Container fluid  style={{height: "30px", zIndex: "1200"}}
                       className=" d-block d-xxl-none position-absolute sticky-top top-0 left-0 "
            >
                {/*<Row>*/}
                <Row style={{pointerEvents:"none"}}>
                    <Col xs={3} className="h-25 text-start position-relative p-0"
                    >
                        <Link to="/">
                            <img
                                // className="logowoche w-100 position-relative"
                                // className="w-100 logowoch logowochemobil position-relative"
                                className=" logowoch logowochemobil position-relative w-100"
                                alt=""
                                src={
                                    transparent ? LogoEuropawocheTransparent : LogoEuropawoche
                                }
                                // style={
                                //     transparent ? {} : {boxShadow: "0px 3px 6px #00000029",}
                                // }
                            />
                        </Link>
                    </Col>

                    {/*Icon*/}
                    <Col xs={2}
                         className={navi ? "h-25 text-end p-0  d-xl-none" : "text-center d-none "}
                         style={{position: "relative", zIndex: "100",pointerEvents:"all"}}>
                        <Link to="/"
                              onMouseEnter={() => sethoverKarteactive(true)}
                              onMouseLeave={() => sethoverKarteactive(false)}
                        >
                            {
                                (hoverkarteactive || karteactive==='bg-europagelb') ? <img src={IconkarteHover} alt="" className="menuicon" /> : <img className="menuicon" src={Iconkarte} alt="" />
                            }
                        </Link>
                    </Col>

                    {/*Text*/}
                    <Col xs={2}
                         className={navi ? "h-25 text-center d-none d-xl-block" : "text-center d-none"}
                         style={{position: "relative", zIndex: "100",pointerEvents:"all"}}>
                        <Link to="/"
                              className={karteactive + " hover-000 text-decoration-none border-radius8 karte-tabelle-17pt-schwrz-upper p-2 kartelinktext  d-inline-block"}>
                            &nbsp;Karte&nbsp;

                        </Link>
                    </Col>

                    <Col xs={2} className="h-25"></Col>

                    {/*Icon*/}
                    <Col xs={2}
                         className={navi ? "h-25 align-left p-0 d-xl-none" : "text-center d-none "}
                         style={{position: "relative", zIndex: "100",pointerEvents:"all"}}
                    >
                        {/*y*/}
                        <Link to="/tabelle"
                              onMouseEnter={() => sethoverTabelleactive(true)}
                              onMouseLeave={() => sethoverTabelleactive(false)}
                        >
                            {
                                (hovertabelleactive || tabelleactive==='bg-europagelb') ? <img src={IcontabelleHover} alt="" className="menuicon "/> : <img src={Icontabelle} alt="" className="menuicon"/>
                            }
                        </Link>
                    </Col>

                    {/*Text*/}
                    <Col xs={2}
                         className={navi ? "h-25 text-center d-none d-xl-block" : "text-center d-none"}
                         style={{pointerEvents:"all"}}
                    >
                        <Link to="/tabelle"
                              style={{pointerEvents:"all"}}
                              className={tabelleactive + " hover-000 text-decoration-none border-radius8 karte-tabelle-17pt-schwrz-upper p-2 kartelinktext d-inline-block"}>
                            Tabelle
                        </Link>
                    </Col>

                    {/*<Col xs={6} className="offset- d-none"></Col>*/}

                    <Col xs={3}
                         style={{pointerEvents:"all"}}
                         className={
                        // jugend ? "p-0" : "d-none"
                        jugend ? "p-0 text-end" : "d-none"

                    }>
                        {
                            transparent ?
                                <img alt=""
                                     // className="headericon logojj jjmobil w-100"
                                     className="headericon logojj jjmobil w-100"
                                     src={jjhover}
                                     onMouseEnter={() => setJjhover(LogoJugendjahrHoverTransparent)}
                                     onMouseLeave={() => setJjhover(LogoJugendjahrTransparent)}
                                     onClick={(e) => handleJugendjahr()}
                                />
                                :
                                <img alt=""
                                     // className="headericon logojj jjmobil  w-100"
                                     className="headericon logojj jjmobil w-100 "
                                     src={jjhover}
                                     onMouseEnter={() => setJjhover(LogoJugendjahrHover)}
                                     onMouseLeave={() => setJjhover(LogoJugendjahr)}
                                     onClick={(e) => handleJugendjahr()}
                                     // style={{boxShadow: "0px 3px 6px #00000029"}}
                                />
                        }
                    </Col>
                </Row>
            </Container>

            <Container className="fullwidth d-none d-xxl-block" style={ContainerStyle}>
                <Row>
                    <Col>
                        <Container fluid className="p-0 overflow-hidde haase">
                            <Row>
                                <Col xs={3} className="">
                                    {/*<Container className="position-relative p-0" style={{zIndex: "1600"}}>*/}
                                    <Container className="position-relative p-0"
                                               style={
                                                   ewonly ? {zIndex: "2900"} : {zIndex: "1600"}
                                               }
                                    >
                                        <Link to="/">
                                            <img

                                                className={
                                                    ewfixed ? "position-fixed logowoche" : "logowoche"
                                                }

                                                src={
                                                    transparent ? LogoEuropawocheTransparent : LogoEuropawoche
                                                } alt=""
                                                style={
                                                    transparent ? {} : {boxShadow: "0px 3px 6px #00000029"}
                                                }
                                            />
                                        </Link>
                                    </Container>
                                </Col>

                                <Col xs={3} className="offset-6" style={ewonly ? {display: "none"} : {display: "block"}}
                                >
                                    <Container style={{height: "140px", zIndex: "1500"}} className="cursor-pointer position-relative text-end p-0">
                                        {
                                            transparent ?
                                                <img alt=""
                                                     className="logojj"
                                                     src={jjhover}
                                                     onMouseEnter={() => setJjhover(LogoJugendjahrHoverTransparent)}
                                                     onMouseLeave={() => setJjhover(LogoJugendjahrTransparent)}
                                                     onClick={(e) => handleJugendjahr()}
                                                />
                                                :
                                                <img alt=""
                                                     className="logojj"
                                                     src={jjhover}
                                                     onMouseEnter={() => setJjhover(LogoJugendjahrHover)}
                                                     onMouseLeave={() => setJjhover(LogoJugendjahr)}
                                                     onClick={(e) => handleJugendjahr()}
                                                     style={{boxShadow: "0px 3px 6px #00000029"}}
                                                />
                                        }
                                    </Container>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
export default Logos;
