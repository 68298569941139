import {useAppDispatch, useAppSelector} from "../app/hooks";
import React, {useEffect, useState} from "react";
import {Button, Col, Container, Row} from "react-bootstrap";
import VaBox from "./VaBox";
import {Link} from "react-router-dom";
import Modalclose from "../assets/02_EYY/zu.svg"
import ModalcloseHover from "../assets/02_EYY/zu-hover.svg"
import {setModalStatus} from "../features/modal/modalSlice";


interface Props {
    activeRow: number,
    karte: boolean
}


const Zeilenansicht = ({activeRow, karte}: Props) => {
    const ZeilenansichtData = useAppSelector((state) => state.zeilenansicht.value)
    const bundeslandData = useAppSelector((state) => state.bundesland.value)
    const [slug, setSlug] = useState("bundesland/")
    const [bgclass] = useState(karte ? "" : "bg-white")
    const [wappen, setWappen] = useState('')
    const [landname, setLandname] = useState('')
    const [closeimagehover, setCloseimagehover] = useState(Modalclose)
    const dispatch = useAppDispatch()

    useEffect(() => {
        setSlug("/bundesland/" + ZeilenansichtData.inhalt)
    }, [ZeilenansichtData])

    useEffect(() => {
        bundeslandData.map(function (item) {
            if (decodeURI(item.slug) === decodeURI(ZeilenansichtData.inhalt)) {
                setWappen(item.acf.wappen)
                setLandname(item.title.rendered)
            }
        })
    }, [bundeslandData, ZeilenansichtData.inhalt])

    if ((ZeilenansichtData.zeile === activeRow) || (karte)) {
        return (
            <Container fluid className="fullwidth mb-4 mt-">
                <Row className={bgclass}>
                    <Col>
                        <Container fluid className="color-black  p-0 position-relative">
                            <Row xs={1} sm={3} className="justify-content-center">
                                <VaBox karte={karte}/>
                            </Row>
                            <Row xs={1} className="bg-white">
                                <Col className="text-center mb-4">
                                    <Link to={slug}>
                                        <Button className="weitere-details"
                                        >
                                            <span className="ort-16pt-semibold-schwarz-upper">Weitere Details</span>
                                        </Button>
                                    </Link>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>
        )
    } else {
        return (<></>)
    }
}

export default Zeilenansicht;
