import {useAppSelector} from "../app/hooks";
import React, {CSSProperties, useEffect, useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import GetMonat from "../actions/GetMonat";


interface Props {
    karte: boolean
}

const pStyle: CSSProperties = {
    // textOverflow: "ellipsis",
    // display: "-webkit-box",
    // overflow: "hidden",
    // WebkitLineClamp: "5",
    // WebkitBoxOrient: "vertical"
}

const VaBox = ({karte}:Props) => {
    const ZeilenansichtData = useAppSelector((state) => state.zeilenansicht.value)
    const veranstaltungData = useAppSelector((state) => state.veranstaltung.value)

    const [localslug, setLocalslug] = useState("")

    const shadowStyle: CSSProperties = {
        boxShadow: "5px 5px"
    }

    useEffect(() => {
        setLocalslug(ZeilenansichtData.inhalt)
        if (ZeilenansichtData.inhalt === 'sh') setLocalslug('schleswig-holstein')
        if (ZeilenansichtData.inhalt === 'bw') setLocalslug('baden-wu%cc%88rttemberg')
        if (ZeilenansichtData.inhalt === 'rpf') setLocalslug('rheinland-pfalz')
    }, [ZeilenansichtData])

    let success = 0

    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const thisday = Number(year + month + day)

    return (
        <>
            {
                veranstaltungData.map(function (item, index) {
                    let title = item.title;
                    let bild = null
                    bild = item.acf?.foto_hauptbild?.sizes?.large
                    let monat: any
                    monat = GetMonat(item.acf?.datum_von?.substring(4, 6))
                    let monatEnde: any
                    monatEnde = GetMonat(item.acf?.datum_bis?.substring(4, 6))

                    let datumVon = item.acf?.datum_von?.substring(6, 8) + '. ' + monat + ' ' + item.acf?.datum_von?.substring(0, 4)
                    let datumBis = item.acf?.datum_bis?.substring(6, 8) + '.' + monatEnde + '.' + item.acf?.datum_bis?.substring(0, 4)

                    let thisdatum = Number(item.acf?.datum_von)

                    if ((item.acf?.bundesland.post_name === localslug) && (success < 3) && (thisdatum >= thisday)) {
                        success = success + 1
                        return (
                            <Col key={index}>
                                <Container className="overflow-hidden mt-5 mb-4 p-0 text-start">
                                    <Row>
                                        <Col style={shadowStyle}>
                                            <img src={bild} alt="" className="w-100 p-0 mb-3"/>

                                            <span className="h3-18pt-bold-schwarz-upper mb-2 d-block">
                                                {title}
                                            </span>

                                            <span className="datum-18pt-black-blau fw-900">{datumVon}</span>


                                            {
                                                (datumVon.length>2 && item?.acf?.uhrzeit_von) ? <span className="h3-18pt-bold-schwarz-upper ms-3 me-3">//</span> : <></>
                                            }


                                            {
                                                item?.acf?.uhrzeit_von ? <span className="datum-18pt-black-blau">{item?.acf?.uhrzeit_von} Uhr</span> : <></>
                                            }


                                            <div className="mt-1">
                                                <span className="ort-16pt-bold-schwarz-upper">{item?.acf?.adresse_ort}</span>
                                                {(item?.acf?.adresse_ort && item?.acf?.veranstaltungsort) ?
                                                    <span className="h3-18pt-bold-schwarz-upper ms-2 me-2">//</span> : <></>}
                                                <span className="ort-16pt-semibold-schwarz-upper">{item?.acf?.veranstaltungsort}</span>
                                            </div>

                                            <p style={pStyle} className="fließtextI-16pt-regular-schwarz teasertext pt-1 line-height-22">
                                                {/*{item?.acf?.teasertext?.substring(0, 250)}*/}
                                                {item?.acf?.teasertext}
                                            </p>
                                        </Col>
                                    </Row>
                                </Container>
                            </Col>
                        )
                    } else {
                        return (<></>)
                    }
                })
            }

            {
                veranstaltungData.map(function (item, index) {
                    let title = item.title;
                    let bild = null
                    bild = item.acf?.foto_hauptbild?.sizes?.large
                    let monat: any
                    monat = GetMonat(item.acf?.datum_von?.substring(4, 6))
                    let monatEnde: any
                    monatEnde = GetMonat(item.acf?.datum_bis?.substring(4, 6))

                    let datumVon = item.acf?.datum_von?.substring(6, 8) + '. ' + monat + ' ' + item.acf?.datum_von?.substring(0, 4)
                    let datumBis = item.acf?.datum_bis?.substring(6, 8) + '.' + monatEnde + '.' + item.acf?.datum_bis?.substring(0, 4)

                    let thisdatum = Number(item.acf?.datum_von)

                    if ((item.acf?.bundesland.post_name === localslug) && (success < 3) && (thisdatum < thisday)) {
                        success = success + 1
                        return (
                            <Col key={index}
                                 className="mt-5 mb-5 position-relative"
                            style={{backgroundColor:"#00339926",top:"-1.5rem"}}

                            >
                                <div className="position-absolute archiv-14pt-semibold-weiss mt-1">Archiv</div>
                                <Container className="overflow-hidden p-0 text-start position-relative"
                                           style={{marginTop:"28px"}}

                                >

                                    <Row>
                                        <Col style={shadowStyle}>
                                            <img src={bild} alt="" className="w-100 p-0 mb-3"/>

                                            <span className="h3-18pt-bold-schwarz-upper mb-2 d-block">
                                                {title}
                                            </span>

                                            <span className="datum-18pt-black-blau fw-900">{datumVon}</span>
                                            <span className="h3-18pt-bold-schwarz-upper ms-3 me-3">//</span>
                                            <span className="datum-18pt-black-blau">{item?.acf?.uhrzeit_von} Uhr</span>

                                            <div className="mt-1">
                                                <span className="ort-16pt-bold-schwarz-upper">{item?.acf?.adresse_ort}</span>
                                                {(item?.acf?.adresse_ort && item?.acf?.veranstaltungsort) ?
                                                    <span className="h3-18pt-bold-schwarz-upper ms-2 me-2">//</span> : <></>}
                                                <span className="ort-16pt-semibold-schwarz-upper">{item?.acf?.veranstaltungsort}</span>
                                            </div>

                                            <p style={pStyle} className="fließtextI-16pt-regular-schwarz teasertext pt-1 line-height-22">
                                                {/*{item?.acf?.teasertext?.substring(0, 250)}*/}
                                                {item?.acf?.teasertext}
                                            </p>
                                        </Col>
                                    </Row>
                                </Container>
                            </Col>
                        )
                    } else {
                        return (<></>)
                    }
                })
            }

            {/*{(success > 0 && success < 2) ? <><Col className="bg-whit"><Container><Row><Col></Col></Row></Container></Col></> : <></>}*/}
            {/*{(success > 0 && success < 3) ? <><Col className="bg-whit"><Container><Row><Col></Col></Row></Container></Col></> : <></>}*/}



            {
                success === 0 ? <><Col className="col-12 p-0"><Container  className="fullwidth text-center p-5 bg-whit"><p>Es liegen aktuell keine Veranstaltungen vor.</p>
                </Container></Col></> : <></>
            }
        </>
    )
}

export default VaBox;
