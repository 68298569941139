import {Col} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import GetMonat from "../actions/GetMonat";

interface Props {
    item: any,
    reverse: boolean
    archiv:boolean
}

const BlockVa = ({item, reverse,archiv}: Props) => {
    const [ahover, setAhover] = useState(false)
    const [link0, setLink0] = useState('')
    const [link1, setLink1] = useState('')
    const [link2, setLink2] = useState('')

    let monat: any
    monat = GetMonat(item.acf?.datum_von?.substring(4, 6))
    let monatEnde: any
    monatEnde = GetMonat(item.acf?.datum_bis?.substring(4, 6))

    let datumVon = item.acf?.datum_von?.substring(6, 8) + '. ' + monat + ' ' + item.acf?.datum_von?.substring(0, 4)
    let datumBis = item.acf?.datum_bis?.substring(6, 8) + '. ' + monatEnde + ' ' + item.acf?.datum_bis?.substring(0, 4)

    useEffect(() => {
        let links = item.acf.links
        if (links !== false) {
            links.map(function (mylink: any, index: number) {
                if (index === 0) {
                    setLink0('<a href="' + mylink.link + '" ' +
                        '                   target="_blank"' +
                        '>' + mylink.linktitel + '</a>')
                }
                if (index === 1) {
                    setLink1('<a href="' + mylink.link + '"' +
                        '                   target="_blank"' +
                        '>' + mylink.linktitel + '</a>')
                }
                if (index === 2) {
                    setLink2('<a href="' + mylink.link + '"' +
                        '                   target="_blank"' +
                        '>' + mylink.linktitel + '</a>')
                }
            })
        }
    }, [item])


    return (
        <>
            {
                archiv ? <Col xs={12} lg={12} style={{backgroundColor:"#00339926"}}>Archiv</Col> : <></>
            }

            <Col xs={12} lg={12}
                 style={
                archiv ? {backgroundColor:"#00339926"} : {}
                 }

                 className={
                     reverse ? "overflow-hidden text-lg-end" : "overflow-hidden text-start"
                 }
            >
                <img src={item.acf.foto_hauptbild.sizes.large} alt={item.acf.foto_hauptbild.title}
                     className="d-block d-md-none mb-3" style={{width: "100%", maxWidth: "458px"}}
                />

                <img src={item.acf.foto_hauptbild.sizes.large} alt={item.acf.foto_hauptbild.title}
                     className={
                         reverse ? "d-none d-md-block w-auto float-end" : "d-none d-md-block w-auto float-start"
                     }
                     style={
                         reverse ? {marginLeft: "24px", marginBottom: "29px", maxWidth: "458px"} : {
                             marginRight: "24px",
                             marginBottom: "29px",
                             maxWidth: "458px"
                         }
                     }
                />

                <span className="datum-18pt-black-blau">{datumVon}</span>
                {
                    datumBis.length > 5 ? <><span className="datum-18pt-black-blau"> - {datumBis}</span></> : <></>
                }
                {
                    (item.acf?.uhrzeit_von || item.acf?.uhrzeit_bis) ? <span className="h3-18pt-bold-schwarz-upper mx-3">//</span> : <></>
                }

                <span className="datum-18pt-black-blau">{item.acf?.uhrzeit_von}</span>
                {
                    item.acf?.uhrzeit_bis ? <span className="datum-18pt-black-blau"> - {item.acf?.uhrzeit_bis}</span> : <></>
                }
                {
                    (item.acf?.uhrzeit_von || item.acf?.uhrzeit_bis) ? <span className="datum-18pt-black-blau"> Uhr</span> : <></>
                }

                <h3 className="h2-22pt-bold-schwarz-upper mt-1"
                    // dangerouslySetInnerHTML={{__html: item.title?.rendered}}
                    dangerouslySetInnerHTML={{__html: item.title}}
                />

                <h4 className="h3-18pt-bold-schwarz">{item.acf?.untertitel}</h4>
                <span className="fließtextI-16pt-regular-schwarz color-black d-block mt-2">{item.acf?.veranstaltername}</span>
                <span className="ort-16pt-bold-schwarz-upper d-block mt-2">{item.acf?.veranstaltungsort}</span>

                <span className="ort-16pt-semibold-schwarz-upper d-block">
                    {item.acf?.adresse_strasse} {item.acf?.adresse_zusatz}
                    {
                        ((item.acf?.adresse_strasse.length > 2 || item.acf?.adresse_zusatz.length > 2) && (item.acf?.adresse_plz.length > 2 || item.acf?.adresse_ort.length > 2)) ? <> // </> : <></>
                    }
                    {item.acf?.adresse_plz} {item.acf?.adresse_ort}
                </span>

                <span className="fließtextI-16pt-regular-schwarz color-black d-block mt-2 line-height-22"
                      dangerouslySetInnerHTML={{__html: item.acf?.beschreibungstext}}/>

                <a className="link-14pt-semibild-schwarz-upper text-decoration-none"
                   style={
                       item.acf?.link_veranstaltung.length>2 ? {} : {display:"none"}
                   }
                   target="_blank"
                   href={item.acf?.link_veranstaltung}
                   onMouseEnter={() => setAhover(true)}
                   onMouseLeave={() => setAhover(false)}
                >
                    {
                        ahover ?
                            <svg style={{position: "relative", top: "-2px", left: "5px"}} xmlns="http://www.w3.org/2000/svg" width="10.8" height="18"
                                 viewBox="0 0 10.8 18">
                                <path style={{fill: "#003399"}} className="a" d="M0,21.5l7.626,7.574L.008,36.483,1.64,38l9.16-8.91L1.648,20Z"
                                      transform="translate(0 -20)"/>
                            </svg> :
                            <svg style={{position: "relative", top: "-2px"}} xmlns="http://www.w3.org/2000/svg" width="10.8" height="18"
                                 viewBox="0 0 10.8 18">
                                <path style={{fill: "#333"}} className="a" d="M0,21.5l7.626,7.574L.008,36.483,1.64,38l9.16-8.91L1.648,20Z"
                                      transform="translate(0 -20)"/>
                            </svg>
                    }

                    <span className="ms-3">ZUR WEBSITE</span>
                </a>

                {link0.length > 0 ? <div className="morelinks link-14pt-semibild-schwarz-upper text-decoration-none" dangerouslySetInnerHTML={{__html: link0}}></div> : <></>}
                {link1.length > 0 ? <div className="morelinks link-14pt-semibild-schwarz-upper text-decoration-none" dangerouslySetInnerHTML={{__html: link1}}></div> : <></>}
                {link2.length > 0 ? <div className="morelinks link-14pt-semibild-schwarz-upper text-decoration-none" dangerouslySetInnerHTML={{__html: link2}}></div> : <></>}

                {
                    item.acf.foto_hauptbild.caption ?
                        <div className="">
                            <hr
                                className="d-inline-block mb-2"
                                style={{width: "200px", height: "1px", color: "#333"}}
                            />
                            <span className="foto-14pt-regular-schwarz d-block">{item.acf.foto_hauptbild.caption}</span>
                        </div>
                        :
                        <></>
                }

            </Col>
            {/*{item.acf?.links}<br />*/}
            {/*{item.acf?.dokumente}<br />*/}
            {/*{item.acf?.bundesland}<br />*/}
        </>
    )
}

export default BlockVa;
