import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {store} from './app/store';
import {Provider} from 'react-redux';

// import 'css/style.css'
import './css/style.css';
import './css/kartenstyle.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/shariff/shariff.complete.css'

import {createInstance, MatomoProvider} from '@datapunt/matomo-tracker-react'

const instance = createInstance({
    urlBase: 'https://statistik.europawoche2022.de/',
    siteId: 2,
    // userId: 'UID76903202', // optional, default value: `undefined`.
    // trackerUrl: 'https://statistik.europawoche2022.de/tracking.php', // optional, default value: `${urlBase}matomo.php`
    // srcUrl: 'https://statistik.europawoche2022.de/tracking.js', // optional, default value: `${urlBase}matomo.js`
    disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
    heartBeat: { // optional, enabled by default
        active: true, // optional, default value: true
        seconds: 10 // optional, default value: `15
    },
    linkTracking: false, // optional, default value: true
    configurations: { // optional, default value: {}
        // any valid matomo configuration, all below are optional
        disableCookies: true,
        setSecureCookie: true,
        setRequestMethod: 'POST'
    }
})


ReactDOM.render(
    <React.StrictMode>
        <MatomoProvider value={instance}>
            <Provider store={store}>
                <App/>
            </Provider>
        </MatomoProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

