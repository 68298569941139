import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from "../../app/store";

interface state {
    value: boolean
}

const initialState: state = {
    value: true
    // value: false
}

export const showrollupSlice = createSlice({
    name: 'showrollup',
    initialState,
    reducers: {
        setShowrollup: (state, action: PayloadAction<boolean>) => {
            state.value = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const {setShowrollup} = showrollupSlice.actions
export const selectShowrollup = (state: RootState) => state.showrollup.value

export default showrollupSlice.reducer
