import {Col, Container, Row} from "react-bootstrap";
import {useAppSelector} from "../app/hooks";
import {CSSProperties, useState} from "react";
import {Link} from "react-router-dom";
import Linkmaker from "../actions/Linkmaker";
import Instagram from "./../assets/06_Footer/instagram.svg"
import InstagramHover from "./../assets/06_Footer/instagram-hover.svg"
import LogoEK from "./../assets/06_Footer/logo-kommission.svg"
import LogoParlament from "./../assets/06_Footer/logo-parlament.svg"
import LogoED from "./../assets/06_Footer/europe-direct.svg"


const FooterStyle: CSSProperties = {
    height: "72px",
    color: "#000",
    marginTop:"150px",
    boxShadow: "0px 0px 6px #00000029"
}

interface Props{
    absolute:boolean
}

const Footer = ({absolute}:Props) => {
    const footerData = useAppSelector((state) => state.footermenu.value)
    const [containerclass2] = useState(absolute ? "d-none d-xxl-block footer2  text-center fullwidth bg-white position-fixed bottom-0" : "d-none d-xl-block  text-center fullwidth bg-white bottom-0")
    const [instahover, setInstahover] = useState(Instagram)

    return (
        <>
            {/*DESKTOP*/}
            <Container className={containerclass2} style={FooterStyle}>
                <Row className="h-100">
                    <Col className="h-100">
                        <Container fluid className="h-100 p-0">
                            <Row className="h-100">
                                <Col xs={6} className="my-auto">
                                    <Container fluid className="text-start p-0 logoline">
                                        <a href="https://www.europarl.europa.eu/germany/de" target="_blank"><img src={LogoParlament} alt="" className="me-5 lf1"/></a>
                                        <a href="https://germany.representation.ec.europa.eu/nachrichten-und-veranstaltungen/veranstaltungen_de" target="_blank"><img src={LogoEK} alt="" className="lf2"/></a>
                                        <a href="https://germany.representation.ec.europa.eu/aktivitaten-rund-um-den-europa-tag-deutschland_de" target="_blank"><img src={LogoED} className="ms-5 lf3"/></a>
                                    </Container>
                                </Col>
                                <Col xs={2} className="my-auto text-start ms-">
                                    <a href="https://www.instagram.com/explore/tags/europawoche2022/" target="_blank">
                                        <img
                                            src={instahover}
                                            onMouseEnter={() => setInstahover(InstagramHover)}
                                            onMouseLeave={() => setInstahover(Instagram)}
                                            alt="" className="m- cursor-pointer"/>
                                    </a>
                                </Col>
                                <Col xs={4} className="my-auto p-0 text-center">
                                    {
                                        footerData.map(function (item, index) {
                                            return (
                                                <Link key={index} to={Linkmaker(item.url)}
                                                      className="text-decoration-none me-5 foto-14pt-regular-schwarz">{item.title}</Link>
                                            )
                                        })
                                    }
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>

            {/*MOBIL*/}
            <Container className="d-xxl-none fullwidth bg-white position-fixed bottom-0 pt-1"
            style={{boxShadow: "0px 0px 6px #00000029"}}
            // style={{boxShadow: "0px 0px 6px #00000029", zIndex:"2000"}}
            >
                <Row>
                    <Col xs={4} xxl={2} className="my-auto order-3 order-xxl-1 text-center pb-3">
                        <a href="https://www.europarl.europa.eu/germany/de" target="_blank"><img src={LogoParlament} className="mobilefooterimg1"/></a>
                    </Col>
                    <Col xs={4} xxl={2} className="my-auto order-3 order-xxl-2 text-center pb-3">
                        <a href="https://germany.representation.ec.europa.eu/nachrichten-und-veranstaltungen/veranstaltungen_de" target="_blank"><img src={LogoEK} className="mobilefooterimg2"/></a>
                    </Col>
                    <Col xs={4} xxl={2} className="my-auto order-3 order-xxl-3 text-center pb-3">
                        <a href="https://germany.representation.ec.europa.eu/aktivitaten-rund-um-den-europa-tag-deutschland_de" target="_blank"><img src={LogoED} className="mobilefooterimg3"/></a>
                    </Col>
                    <Col xs={12} xxl={2} className="my-auto text-center text-xxl-left order-1 order-xxl-4">
                        <a href="https://www.instagram.com/explore/tags/europawoche2022/" target="_blank">
                            <img
                                src={instahover}
                                onMouseEnter={() => setInstahover(InstagramHover)}
                                onMouseLeave={() => setInstahover(Instagram)}
                                alt="" className=" cursor-pointer mt-2 mb-0 mt-sm-0 mb-sm-0 instalogo"
                            />
                        </a>
                    </Col>
                    <Col xs={12} xxl={4} className="my-auto text-center order-2 order-xxl-4 py-1">
                        {
                            footerData.map(function (item, index) {
                                return (
                                    <Link key={index} to={Linkmaker(item.url)}
                                          className="text-decoration-none m-4 foto-14pt-regular-schwarz ">{item.title}</Link>
                                )
                            })
                        }
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Footer;
