import {useAppDispatch, useAppSelector} from "../app/hooks";
import React, {useEffect, useState} from "react";
import {Button, Col, Container, Row} from "react-bootstrap";
import VaBox from "./VaBox";
import {Link} from "react-router-dom";
import Modalclose from "../assets/03_Karte/zu.svg"
import ModalcloseHover from "../assets/03_Karte/zu-hover.svg"
import {setModalStatus} from "../features/modal/modalSlice";


interface Props {
    activeRow: number,
    karte: boolean
}


const Zeilenansicht = ({activeRow, karte}: Props) => {
    const ZeilenansichtData = useAppSelector((state) => state.zeilenansicht.value)
    const bundeslandData = useAppSelector((state) => state.bundesland.value)
    const [slug, setSlug] = useState("bundesland/")
    const [bgclass] = useState(karte ? "" : "bg-white")
    const [wappen, setWappen] = useState('')
    const [landname, setLandname] = useState('')
    const [closeimagehover, setCloseimagehover] = useState(Modalclose)
    const dispatch = useAppDispatch()

    useEffect(() => {
        setSlug("/bundesland/" + ZeilenansichtData.inhalt)
    }, [ZeilenansichtData])

    useEffect(() => {
        bundeslandData.map(function (item) {
            if (decodeURI(item.slug) === decodeURI(ZeilenansichtData.inhalt)) {
                console.log(item)
                setWappen(item.acf.wappen)
                setLandname(item.title.rendered)
            }
        })
    }, [bundeslandData, ZeilenansichtData.inhalt])

    if ((ZeilenansichtData.zeile === activeRow) || (karte)) {
        return (
            <>
                <Container className="bg-white container-karte check" style={{boxShadow: "0px 0px 6px #00000080"}}>
                    <Row className="pt-0 position-relative">
                        <div
                            className="position-absolute text-end p-0"
                            style={{position: "absolute", right: "0px", top: "0", width: "auto", zIndex: "10000"}}

                        >
                            <img src={closeimagehover} alt="" className="cursor-pointer"
                                 onMouseEnter={() => setCloseimagehover(ModalcloseHover)}
                                 onMouseLeave={() => setCloseimagehover(Modalclose)}
                                 onClick={() => dispatch(setModalStatus(false))}
                            />
                        </div>
                    </Row>
                    <Row>
                        <Col className="">
                            <Container fluid className="p-0">
                                <Row className="position-relative" style={{top: "-40px", zIndex: "500", height:"88px"}}>
                                    <Col className="text-center">
                                        <img src={wappen} alt="" style={{width:"73px",maxHeight:"88px",maxWidth:"73px"}}/>

                                    </Col>
                                </Row>
                                <Row className="pt-0 position-relative" style={{marginTop:"-37px"}}>
                                    <Col className="text-center">
                                        <h1 className="karteh1 mb-0">VERANSTALTUNGEN ZUR EUROPAWOCHE</h1>
                                        <span className="bundesland-20pt-semibold-weiss-upper color-black">
                                            {landname}
                                            </span>
                                    </Col>
                                </Row>
                            </Container>

                            <Container fluid className="color-black  p-0 position-relative" style={{marginTop:"-10px"}}>
                                <Row xs={1} sm={3} className="justify-content-center">
                                    <VaBox karte={karte}/>
                                </Row>
                                <Row xs={1} className="">
                                    <Col className="text-center mb-4">
                                        <Link to={slug}>
                                            <Button className="weitere-details"
                                            >
                                                <span className="ort-16pt-semibold-schwarz-upper">Weitere Details</span>
                                            </Button>
                                        </Link>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    } else {
        return (<></>)
    }
}

export default Zeilenansicht;
