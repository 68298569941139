export const URL_OPTIONS = "https://backend.europawoche2022.de/wp-json/acf/v2/options"
export const URL_MAINMENU = "https://backend.europawoche2022.de/wp-json/wp/v2/mainmenu"
export const URL_FOOTERMENU = "https://backend.europawoche2022.de/wp-json/wp/v2/footermenu"

export const URL_MEDIA = "https://backend.europawoche2022.de/wp-json/wp/v2/media?per_page=100&page=1"

export const URL_START = "https://backend.europawoche2022.de/wp-json/wp/v2/pages/95"
export const URL_DATENSCHUTZ = "https://backend.europawoche2022.de/wp-json/wp/v2/pages/116"
export const URL_IMPRESSUM = "https://backend.europawoche2022.de/wp-json/wp/v2/pages/118"
export const URL_BUNDESLAND = "https://backend.europawoche2022.de/wp-json/wp/v2/bundesland?per_page=100&page=1&orderby=title&order=asc"
// export const URL_VA = "https://backend.europawoche2022.de/wp-json/wp/v2/veranstaltung?per_page=100&page=1&orderby=title&order=asc"
export const URL_VA = "https://backend.europawoche2022.de/wp-json/sortiert/v2/veranstaltung"

export const LINKMAKER_REPLACE = "https://backend.europawoche2022.de"
export const LINKMAKER_BASE = ""

