import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from "../../app/store";
import {MenuDataT} from "../../library/MenuT";

interface footermenuState {
    value:MenuDataT
}

const initialState: footermenuState = {
    value: [{
        ID:0,
        title:'',
        url:'',
    }],
}

export const footermenuSlice = createSlice({
    name: 'footermenu',
    initialState,
    reducers: {
        setFootermenu: (state, action: PayloadAction<MenuDataT>) => {
            state.value = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const {setFootermenu} = footermenuSlice.actions
export const selectFootermenu = (state: RootState) => state.footermenu.value

export default footermenuSlice.reducer
