import {useAppDispatch, useAppSelector} from "../app/hooks";
import {useEffect, useState} from "react";
import DocumentMeta from "react-document-meta"


const Metatags = () => {
    const metaData = useAppSelector((state) => state.meta.value)
    const [meta, setMeta] = useState({
            title: '',
            description: '',
            canonical: '',
        }
    )

    useEffect(() => {
        setMeta({
                title: metaData.title,
                description: metaData.description,
                canonical: metaData.canonical,
            }
        )
    }, [metaData])

    return (
        <DocumentMeta {...meta}></DocumentMeta>
    )
}

export default Metatags;
