import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from "../../app/store";

interface state {
    value: boolean
}

const initialState: state = {
    value: true
    // value: false
}

export const haserollupSlice = createSlice({
    name: 'haserollup',
    initialState,
    reducers: {
        setHaserollup: (state, action: PayloadAction<boolean>) => {
            state.value = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const {setHaserollup} = haserollupSlice.actions
export const selectHaserollup = (state: RootState) => state.haserollup.value

export default haserollupSlice.reducer
