import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from "../../app/store";

interface state {
    value: {
        status:boolean,
        slug:string
    }
}

const initialState: state = {
    value:{
        status:false,
        // status:true,
        slug:''
        // slug:'sachsen'
    }
}

export const modalSlice = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        setModalStatus: (state, action: PayloadAction<boolean>) => {
            state.value.status = action.payload
        },
        setModalSlug: (state, action: PayloadAction<string>) => {
            state.value.slug = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const {setModalStatus} = modalSlice.actions
export const {setModalSlug} = modalSlice.actions

export const selectModal = (state: RootState) => state.modal.value
export default modalSlice.reducer
