import {Col, Container, Row} from "react-bootstrap";
import React from "react";
import {useAppDispatch, useAppSelector} from "../app/hooks";
import {setTitle} from "../features/meta/metaSlice";
import {Link} from "react-router-dom";
import PfeilPrev from "../assets/05_Detailseite/pf-vor-zurueck.svg";
import FooterNavigation from "../components-high/FooterNavigation";
import Logos from "../components-high/Logos";
import {setHaserollup} from "../features/haserollup/haserollupSlice";


const PageDatenschutz = () => {
    const impressum = useAppSelector((state) => state.datenschutz.value)
    const dispatch = useAppDispatch();
    dispatch(setTitle('Europawoche 2022 - Impressum'))

    dispatch(setHaserollup(false))

    // console.log(impressum.content.rendered)
    return (
        <>

            <Logos transparent={false} ewonly={true}  ewfixed={false}  jugend={false}  navi={false}/>

            <Container fluid className="mb-3 position-relative bg-europablau" style={{zIndex:"1500"}}>
                <Row>
                    <Col className="text-center mt-3 pt-1  ps-4">
                        <Link to="/" className="text-decoration-none goleftimage">
                            <img src={PfeilPrev} className="rotate180 me-2 pfeilgoleft" alt=""/>
                            <span className="pt16-regilar-weiss">Startseite</span>
                        </Link>
                    </Col>
                </Row>
            </Container>

            <Container className="fullwidth bg-white pt-5" >
                <Row>
                    <Col>
                        <Container fluid>
                            <Row>
                                <Col className="text-center">
                                    <h1 className="h2-22pt-bold-schwarz-upper" >
                                        Datenschutzhinweise
                                    </h1>
                                </Col>
                            </Row>
                            <Row>

                                <Col className="text-left py-4 color-black">

                                    <div className="foto-14pt-regular-schwarz text-left" dangerouslySetInnerHTML={{__html: impressum?.content?.rendered}}></div>
                                </Col>
                            </Row>

                        </Container>
                    </Col>
                </Row>

            </Container>
            <FooterNavigation absolute={true} />
        </>
    )
}

export default PageDatenschutz;
