import {Col, Container, Row} from "react-bootstrap";
import React, {CSSProperties, useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../app/hooks";
import {setTitle} from "../features/meta/metaSlice";
import LogoJugendjahr from "./../assets/04_Tabelle/logo-eyy-tb.svg"
import FooterNavigation from "../components-high/FooterNavigation";
import Header from "../components-high/Header";
import {setmodaljugendjahr} from "../features/modaljugendjahr/modaljugendjahr";
import Logos from "../components-high/Logos";
import BundeslandRow from "../components-low/BundeslandRow";
import Zeilenansicht from "../components-high/Zeilenansicht";
import {setHaserollup} from "../features/haserollup/haserollupSlice";
import {setModalStatus} from "../features/modal/modalSlice";
import {setZeile, setZeileninhalt} from "../features/zeilenansicht/zeilenansichtSlice";

const VorlageStyle: CSSProperties = {
    position: "absolute",
    zIndex: "50",
    backgroundImage: 'url("./../assets/screens/tabelle-va.png")',
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top center",
    width: "100%",
    height: "2406px",
    opacity: "0.9",
    top: "0",
}

const ContainerStyle: CSSProperties = {
    // position: "relative",
    // top: "17px",
}

const PageListe = () => {
    const bundeslandData = useAppSelector((state) => state.bundesland.value)
    const dispatch = useAppDispatch();
    dispatch(setTitle('Europawoche 2022 - Tabelle'))

    const handleJugendjahr = () => {
        dispatch(setmodaljugendjahr(true))
    }

    useEffect(() => {
        dispatch(setZeileninhalt(''))
        dispatch(setZeile(0))
    }, [])

    const css = `body{overflow-y:hidden;}`

    dispatch(setHaserollup(true))

    return (
        <>
            {/*Overlay*/}
            {/*<div style={VorlageStyle}></div>*/}

            <Header/>

            <Logos transparent={true} ewonly={false} ewfixed={false}  jugend={true} navi={true}/>

            <Container className="" style={ContainerStyle}>
                <Row className="pt-5 pt-lg-5">
                    <Col className="mt-1 mt-sm-5 mt-lg-4 pt-lg-2">
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className="text-center">
                        <h1 className="h1-30pt-bold-weiss h1tabelle">
                            VERANSTALTUNGEN ZUR EUROPAWOCHE
                        </h1>
                    </Col>
                </Row>
            </Container>

            {/*Desktop*/}
            <Container fluid className="d-none d-xl-block p-xl-0 pt-0 pt-xl-4 mt-1 mt-xl-5">
                <BundeslandRow bundeslandData={bundeslandData} row={1} spalten={4}/>
            </Container>
            <Container fluid className="d-none d-xl-block  fullwidth p-0">
                <Zeilenansicht activeRow={1} karte={false}/>
            </Container>

            <Container fluid className="d-none d-xl-block  p-xl-0">
                <BundeslandRow bundeslandData={bundeslandData} row={2} spalten={4}/>
            </Container>
            <Container fluid className="d-none d-xl-block fullwidth p-0">
                <Zeilenansicht activeRow={2} karte={false}/>
            </Container>

            <Container fluid className="d-none d-xl-block p-xl-0">
                <BundeslandRow bundeslandData={bundeslandData} row={3} spalten={4}/>
            </Container>
            <Container fluid className="d-none d-xl-block fullwidth p-0">
                <Zeilenansicht activeRow={3} karte={false}/>
            </Container>

            <Container fluid className="d-none d-xl-block p-xl-0">
                <BundeslandRow bundeslandData={bundeslandData} row={4} spalten={4}/>
            </Container>
            <Container fluid className="d-none d-xl-block fullwidth p-0 mb-5">
                <Zeilenansicht activeRow={4} karte={false}/>
            </Container>

            {/*Mobil*/}
            <Container fluid className="d-xl-none p-xl-0 pt-0 pt-xl-4 mt-1 mt-xl-5">
                <BundeslandRow bundeslandData={bundeslandData} row={1} spalten={2}/>
            </Container>
            <Container fluid className="d-xl-none  fullwidth p-0">
                <Zeilenansicht activeRow={1} karte={false}/>
            </Container>

            <Container fluid className="d-xl-none  p-xl-0">
                <BundeslandRow bundeslandData={bundeslandData} row={2} spalten={2}/>
            </Container>
            <Container fluid className="d-xl-none fullwidth p-0">
                <Zeilenansicht activeRow={2} karte={false}/>
            </Container>

            <Container fluid className="d-xl-none p-xl-0">
                <BundeslandRow bundeslandData={bundeslandData} row={3} spalten={2}/>
            </Container>
            <Container fluid className="d-xl-none fullwidth p-0">
                <Zeilenansicht activeRow={3} karte={false}/>
            </Container>

            <Container fluid className="d-xl-none p-xl-0">
                <BundeslandRow bundeslandData={bundeslandData} row={4} spalten={2}/>
            </Container>
            <Container fluid className="d-xl-none fullwidth p-0">
                <Zeilenansicht activeRow={4} karte={false}/>
            </Container>

            <Container fluid className="d-xl-none p-xl-0">
                <BundeslandRow bundeslandData={bundeslandData} row={5} spalten={2}/>
            </Container>
            <Container fluid className="d-xl-none fullwidth p-0">
                <Zeilenansicht activeRow={5} karte={false}/>
            </Container>

            <Container fluid className="d-xl-none p-xl-0">
                <BundeslandRow bundeslandData={bundeslandData} row={6} spalten={2}/>
            </Container>
            <Container fluid className="d-xl-none fullwidth p-0">
                <Zeilenansicht activeRow={6} karte={false}/>
            </Container>

            <Container fluid className="d-xl-none p-xl-0">
                <BundeslandRow bundeslandData={bundeslandData} row={7} spalten={2}/>
            </Container>
            <Container fluid className="d-xl-none fullwidth p-0">
                <Zeilenansicht activeRow={7} karte={false}/>
            </Container>

            <Container fluid className="d-xl-none p-xl-0">
                <BundeslandRow bundeslandData={bundeslandData} row={8} spalten={2}/>
            </Container>
            <Container fluid className="d-xl-none fullwidth p-0 mb-5">
                <Zeilenansicht activeRow={8} karte={false}/>
            </Container>



            {/*<Container style={{height:"100px"}}></Container>*/}

            <FooterNavigation absolute={true}/>


        </>
    )
}

export default PageListe;
