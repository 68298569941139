import {Col, Container, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../app/hooks";
import PfeilHover from "../assets/01_Header/header-pfeil-zu-hover.svg";
import Pfeil from "../assets/01_Header/header-pfeil-zu.svg";
import {setShowrollup} from "../features/showrollup/showrollupSlice";

import headerA from "../assets/01_Header/europawoche-2022-a.jpg"
import headerB from "../assets/01_Header/europawoche-2022-b.jpg"
import headerC from "../assets/01_Header/europawoche-2022-c.jpg"
import headerD from "../assets/01_Header/europawoche-2022-d.jpg"
import headerE from "../assets/01_Header/europawoche-2022-e.jpg"
import headerAll from "../assets/01_Header/europawoche-2022-all.jpg"
import headerAll2 from "../assets/01_Header/europawoche-2022-all-02.jpg"

const Rollup = () => {
    const showrollup = useAppSelector((state) => state.showrollup.value)
    const dispatch = useAppDispatch();
    // dispatch(setTitle('Europawoche 2022 - Impressum'))
    const [top, setTop] = useState(-470)
    const [rollover, setRollover] = useState(false)
    const [time, setTime] = useState(1)
    const [rotate, setRotate] = useState(180)


    useEffect(() => {
        setRollover(showrollup)
    }, [showrollup])

    useEffect(() => {
        if (showrollup) {
            setRotate(0)
            const timer = setTimeout(() => {
                top < 0 ? setTop(top + 4) : setTop(0)
            }, time);
            return () => clearTimeout(timer);
        }

        if (!showrollup) {
            setRotate(180)
            const timer = setTimeout(() => {
                if (top > -470) {
                    setTop(top - 4)
                }
            }, time);
            return () => clearTimeout(timer);
        }
    }, [rollover, top, time])


    const handleRollover = () => {
        dispatch(setShowrollup(!showrollup))
        setRollover(!rollover)
    }

    const [hoverPfeil, setHoverPfeil] = useState(false)

    const handleHoverOn = () => {
        setHoverPfeil(true)
    }

    const handleHoverOff = () => {
        setHoverPfeil(false)
    }

    return (
        <>
            <Container fluid
                       // style={{height: "651px", top: top + "px"}}
                       style={{height: "518px", top: top + "px"}}
                       className="fullwidth position-absolute overflow-hidden p-0">
                <Row>
                    <Col>
                        <Container className="fullwidth p-0  overflow-hidde">

                            <Row className="bg-white overflow-hidde position-relative check"
                                 style={{height: "470px", boxShadow: "0px 3px 6px #00000029", zIndex: "1400"}}>
                                <Col>
                                    <Container fluid className="fullwidth p-0 overflow-hidden"
                                               style={{height: "470px"}}>
                                        <Row className="overflow-hidden position-relative" style={{height: "308px"}}>
                                            <Col className="color-black text-center p-0">
                                                <a href="https://europatag.guben-gubin.eu/" target="_blank">
                                                <img className="" src={headerAll2} alt=""
                                                     style={{position:"relative",objectFit:"cover",objectPosition: "50% 50%", width:"100%",height: "100%",}}
                                                />
                                                </a>
                                            </Col>
                                        </Row>
                                        <Row style={{position: "relative", top: "-51px"}} className="header-slogan-frame">
                                            <Col>
                                                <Container fluid className="p-0">
                                                    <Row>
                                                        <Col xs={12} xl={5} className="offset-xl-7">
                                                            <Container className="bg-europablau py-2">
                                                                <Row>
                                                                    <Col className="pt26-regular-gelb text-center header-slogan">
                                                                        INFORMIEREN – ERLEBEN – MITMACHEN
                                                                    </Col>
                                                                </Row>
                                                            </Container>
                                                        </Col>
                                                    </Row>
                                                </Container>
                                            </Col>
                                        </Row>
                                        {/*<Row style={{position: "relative", top: "-31px"}}>*/}
                                        <Row style={{position: "relative", top: "-35px"}}>
                                            <Col>
                                                <Container fluid className="header-text-frame" >
                                                    <Row>
                                                        <Col xs={12} xl={8} className=" offset-0 offset-xl-2 text-center p-0">
                                                            {/*<p className="pt18-regular-schwarz header-font-size pe-5">*/}
                                                            <p className="pt18-regular-schwarz header-font-size">
                                                                Die <strong>bundesweite Europawoche</strong> findet in diesem Jahr vom <strong>30.
                                                                April bis 9. Mai 2022</strong> statt.<br />
                                                                Gemeinsam mit dem Europäischen Parlament und der Europäischen Kommission laden alle
                                                                Länder zu
                                                                <strong> Veranstaltungen und Aktionen rund um die Europäische Union</strong> ein.
                                                                <br />
                                                                Was passiert in meiner Region? <br />
                                                                Wo kann ich mitmachen, mich informieren, meine Meinung einbringen?
                                                            </p>
                                                        </Col>
                                                    </Row>
                                                </Container>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
                <Row className="">
                    <Col className="">
                        <Container fluid id="pfeillink"
                                   style={{zIndex:"1600"}}
                                   className="cursor-pointer"
                                   onMouseEnter={handleHoverOn}
                                   onMouseLeave={handleHoverOff}
                                   onClick={(e) => handleRollover()}
                        >
                            <Row className="h-100">
                                <Col className="mx-auto p-0 h-100" style={{transform: "rotate(" + rotate + "deg)"}}>
                                    {hoverPfeil ? <img src={PfeilHover} alt="" className="mt-2"/> :
                                        <img src={Pfeil} alt="" className="mt-2"
                                        />}
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Rollup;
