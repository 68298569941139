import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from "../../app/store";

interface state {
    value: {
        zeile:number,
        inhalt:string,
    }
}

const initialState: state = {
    value: {
        zeile:0,
        inhalt:''
    }
}

export const zeilenansichtSlice = createSlice({
    name: 'zeilenansicht',
    initialState,
    reducers: {
        setZeile: (state, action: PayloadAction<number>) => {
            state.value.zeile = action.payload
        },
        setZeileninhalt: (state, action: PayloadAction<string>) => {
            state.value.inhalt = action.payload
            if(action.payload==='bw'){state.value.inhalt = "baden-wu%cc%88rttemberg"}
            if(action.payload==='sh'){state.value.inhalt = "schleswig-holstein"}
            if(action.payload==='mcpom'){state.value.inhalt = "mecklenburg-vorpommern"}
            if(action.payload==='nrw'){state.value.inhalt = "nordrhein-westfalen"}
            if(action.payload==='rpf'){state.value.inhalt = "rheinland-pfalz"}
            if(action.payload==='sa'){state.value.inhalt = "sachsen-anhalt"}
            if(action.payload==='thueringen'){state.value.inhalt = "thu%cc%88ringen"}
        },

    },
})

export const {setZeile} = zeilenansichtSlice.actions
export const {setZeileninhalt} = zeilenansichtSlice.actions

export const selectZeilenansicht = (state: RootState) => state.zeilenansicht.value


export default zeilenansichtSlice.reducer
