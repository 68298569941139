import {useAppSelector} from "../app/hooks";
import {useEffect} from "react";
import {useMatomo} from "@datapunt/matomo-tracker-react";

const Matomo = () => {
    const metaData = useAppSelector((state) => state.meta.value)
    const {trackPageView} = useMatomo()

    useEffect(() => {
        trackPageView({
            documentTitle: metaData.title,
            href: window.location.href,
        })
    }, [metaData,trackPageView])
    return (
     <></>
    )
}
export default Matomo;
